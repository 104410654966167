<template>
  <div class="setting__wrapper_popup">
    <div
      class="close__setting"
      @click="
        setting_style_show
          ? (setting_style_show = false)
          : $emit('close_setting')
      "
    >
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>
    <settingStyle v-if="setting_style_show" />
    <div class="setting__container" v-else>
      <div class="setting__buttons">
        
        <p > Для поплнения баланса</p> <p>пишите в WhatsApp <b>8(999)993-99-63</b></p>
       
        <hr />
       
      </div>
      <div class="setting__info">
        <div class="support__container">
          
         <!-- <p>Сменить пароль или e-mail</p>
        <button class="support__btn"  @click="setting_style_show = true">Профиль</button> --> 
        </div>
        <p>
          Вы авторизованы как: <span>{{ USER_STATE.login }}</span>
        </p>
        <!-- <p>
          Ваш ID: <span>{{ USER_STATE.user_id }}</span>
        </p>--> 
        
        <button class="exit__btn" @click="func_out()">Выйти из аккаунта</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import settingStyle from "@/components/Main/setting_style.vue";
export default {
  components: {
    settingStyle,
  },
  data() {
    return {
      anim: true,
      order_close: false,
      setting_style_show: false,
    };
  },
  methods: {
    func_out() {
      localStorage.clear();
      this.$store.commit("CHANGE_USER", null);
      window.clearInterval(this.interval);
      this.$router.push("/auth");
    },
    func_order_close() {
      let close = !this.order_close;

      let user_state = this.USER_STATE;
      user_state.close_order = close;
      this.$store.commit("CHANGE_USER", user_state);

      let url = "order.php/save_close_order_user";
      let param = {
        close: close ? 1 : 0,
      };
      this.$sendRequest(url, param);
    },
    func_save_setting() {
      let url = "order.php/save_setting_user";
      let param = {
        row: this.row === true ? 1 : 0,
      };
      this.$sendRequest(url, param).then();
    },
    func_show_page(val) {
      this.$emit("show_obj", val);
    },
    func_show_support() {
      window.open("https://srmple.ru/contacts/", "_blank");
    },
  },
  mounted() {
    if (this.USER_STATE) {
      this.order_close = !this.USER_STATE.close_order;
    }
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style></style>
