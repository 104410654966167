<template>
  <div class="products__content">
    <div class="header__product__title">
      <h4>Товары</h4>
    </div>
    <div class="products__flex">
      <div class="group__list">
        <form @submit.prevent="func_save_group">
          <div class="input__add">
            <input
              type="text"
              v-model="group"
              required
              placeholder="Добавить группу"
            />
            <button><i class="fa fa-plus" aria-hidden="true"></i></button>
          </div>
        </form>
        <div class="group__body" v-if="group_list.length > 0">
          <div
            class="group__item"
            v-for="item in group_list"
            :key="item.id"
            :class="{
              selected: selected_group && selected_group.id === item.id,
            }"
            @click="func_select_group_item(item)"
          >
            <div class="title">
              <input
                type="text"
                name=""
                id=""
                v-model="item.value"
                :readonly="item.readonly === true"
                :class="{ edit: !item.readonly }"
              />
            </div>
            <div class="buttons">
              <div class="edit" @click="func_edit_group(item)">
                <i
                  v-if="item.readonly === true"
                  class="fa fa-pencil-square-o edit__icon"
                  aria-hidden="true"
                ></i>
                <i v-else class="fa fa-check save__icon" aria-hidden="true"></i>
              </div>
              <div class="remove" @click="func_remove_group(item)">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableProduct
        :selected_group="selected_group"
        @show_add_product="show_add_product = true"
      />
    </div>
  </div>
</template>

<script>
import TableProduct from "@/components/Products/product_table";
export default {
  components: {
    TableProduct,
  },
  data() {
    return {
      group: "",
      group_list: [],
      selected_group: null,
    };
  },
  methods: {
    func_edit_group(obj) {
      if (obj.readonly) {
        this.group_list.forEach(function (item) {
          item.readonly = true;
        });
        obj.readonly = false;
      } else {
        this.group_list.forEach(function (item) {
          item.readonly = true;
        });
        let url = "order.php/edit_group_product";
        this.$sendRequest(url, obj);
      }
    },
    func_save_group() {
      let url = "order.php/add_group_product";
      this.$sendRequest(url, { value: this.group }).then((result) => {
        if (!result) return;
        result.readonly = result.readonly === "true" ? true : false;
        this.group_list.unshift(result);
        this.group = "";
      });
    },
    func_get_groups() {
      let url = "order.php/get_groups_product";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        result.forEach(function (item) {
          item.readonly = item.readonly === "true" ? true : false;
        });
        this.group_list = result;
      });
    },
    func_select_group_item(obj) {
      this.selected_group = obj;
    },
    func_remove_group(item) {
      if (!item) return;

      if (!item.readonly) {
        item.readonly = true;
        return;
      }
      this.$iosConfirm("Удалить группу?").then(() => {
        let url = "order.php/remove_group_product";
        this.$sendRequest(url, { id: item.id }).then(() => {
          this.group_list.splice(
            this.group_list.findIndex((x) => x.id === item.id),
            1
          );
          this.selected_group = null;
        });
      });
    },
    func_add_product(obj) {
      if (!obj) return;
      this.add_product_new = obj;
      setTimeout(() => {
        this.add_product_new = null;
      }, 1000);
      this.show_add_product = false;
    },
  },
  mounted() {
    this.func_get_groups();
  },
};
</script>

<style>
.add_client-enter {
  transform: translateY(-100%) scale(0);
}

.add_client-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.add_client-enter-to {
  transform: translateY(0) scale(1);
}
.add_client-leave {
  transform: translateY(0) scale(1);
}
.add_client-leave-active {
  transition: 0.3s;
}

.add_client-leave-to {
  transform: translateY(-150%) scale(0);
}

.customer_detail-enter {
  transform: scale(0);
}

.customer_detail-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.customer_detail-enter-to {
  transform: scale(1);
}
.customer_detail-leave {
  transform: scale(1);
}
.customer_detail-leave-active {
  transition: 0.3s;
}

.customer_detail-leave-to {
  transform: scale(0);
}
</style>