<template>
  <div class="zakup__history__wrapper">
    <div class="zakup__history__header">
      <h2></h2>
    </div>
    <div class="zakup__history__body">
      <div class="zakup__filter">
        <div class="form__group">
          <label>Фильтр по дате</label>
          <div class="datepicker_flex">
            <date-range-picker
              v-model="dateRange"
              :locale-data="param"
              opens="right"
              :ranges="false"
              :singleDatePicker="false"
              :auto-apply="true"
            >
            </date-range-picker>
          </div>
        </div>
      </div>
      <div class="zakup__table">
        <table>
          <thead>
            <tr>
              <th>Дата</th>
              <th>Поставщик</th>
              <th>Количество</th>
              <th>Тип оплаты</th>
              <th>Сумма</th>
              <th></th>
              <th>Пометка</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
    <div class="zakup__history__footer">
      <button @click="$emit('prev')">Назад</button>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    DateRangePicker,
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(startDate.getDate() - 29);
    return {
      dateRange: { startDate, endDate },
      param: {
        direction: "ltr",
        format: "dd.mm.yyyy",
        separator: " - ",
        applyLabel: "Сохранить",
        cancelLabel: "Отменить",
        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        monthNames: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
        firstDay: 1,
      },
      history_list: [],
    };
  },
  methods: {
    func_get_data() {},
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style>
</style>