<template>
 <div class="detail__popup__wrapper">
  <DetailModal
      @cancel="func_detail_cancel"
      @save="func_detail_save"
      v-if="modal_detail_show"
      :option="modal_detail_option"
    />
    <div class="details__popup">
      <div id="print2" v-show="false" v-html="print"></div>
      <div class="popup__flex">
        <div class="left__block">
          <div class="header__order_title">
            <h4>Заказ: {{ data.order_num }}</h4>
            <p>
              от
              {{
                new Date(iosDate(data.date_create)).toLocaleDateString() +
                " " +
                new Date(iosDate(data.date_create)).toLocaleTimeString()
              }}
            </p>
          </div>
        </div>
        <div class="right__block">
          <div class="header__back_btn">
            <div @click="func_close_detail()" class="arrow-7">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <form class="detail__form">
        <div class="left__detail__form">
          <DetailHistory
            v-if="history_show"
            @close="history_show = false"
            :order_id="order_id.toString()"
          />
          <div v-else>
            <div class="fire__row">
              <div
                class="fire"
                :class="{ fire_no: !!!Number(data.fire) }"
                @click="func_update_fire"
              >
                <img :src="require('@/assets/order-flame.png')" alt="" />
              </div>
              <Select
                :arr_p="status_arr"
                :selected_p="data.status_id"
                :item="data"
                v-if="status_arr.length > 0"
                @change="func_update_status"
                :device="false"
                :disabled="func_edit_stop()"
              />
            </div>
            <div class="select__container">
 <div class="form__group">
              <span class="label"></span>
              <button class="btn__history" @click="history_show = true">
                История заказа
                <i class="fa fa-history"></i>
              </button>
            </div>
              <div class="form__group">
                <span class="label">Устройство:</span>
                <select
                  v-if="group_arr.length === 0"
                  @click="func_show_setting"
                >
                  <option selected="true" disabled>
                    Отредактируйте в настройках
                  </option>
                </select>
                <select
                  v-else
                  v-model="data.group_id"
                  @change="func_save()"
                  :disabled="func_edit_stop()"
                >
                  <option
                    v-for="item in group_arr"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.value }}
                  </option>
                </select>
                <div class="fa__user">
                  <i aria-hidden="true" class="fa fa-pencil-square-o"></i>
                </div>
              </div>
              <div class="form__group">
                <span class="label">Модель:</span>
                <select
                  class="select__down"
                  v-if="device_arr.length === 0"
                  @click="func_show_setting"
                >
                  <option selected="true" disabled>
                    Отредактируйте в настройках
                  </option>
                </select>
                <select
                  v-else
                  class="select__down"
                  v-model="data.device_id"
                  @change="func_save()"
                  :disabled="func_edit_stop()"
                >
                  <option
                    v-for="item in device_arr.filter(
                      (x) => x.group_id === data.group_id
                    )"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.value }}
                  </option>
                </select>
                <div class="fa__user">
                  <i aria-hidden="true" class="fa fa-pencil-square-o"></i>
                </div>
              </div>
            </div>
            <div class="form__group">
              <span class="label">Клиент:</span>
              <input
                type="text"
                class="blue"
                v-model="customer_name"
                readonly
                :disabled="func_edit_stop()"
              />
              <div
                class="fa__user"
                @click="
                  $router.push({
                    name: 'clients',
                    params: { customer_id: data.customer_id },
                  })
                "
              >
                <i aria-hidden="true" class="fa fa-user-o"></i>
              </div>
            </div>
            <div class="form__group">
              <span class="label">Телефон:</span>
              <input
                class="orderstrok2 blue"
                type="tel"
                v-model="data.phone"
                readonly
                :disabled="func_edit_stop()"
              />
            </div>
            <div class="form__group">
              <span class="label">Телефон 2:</span>
              <input
                class="orderstrok2 blue"
                type="tel"
                v-model="data.phone2"
                readonly
                :disabled="func_edit_stop()"
              />
            </div>
            <div class="form__group">
              <span class="label">{{ FIELDS_STATE.imei }}:</span>
              <input
                type="text"
                v-model="data.imei"
                @change="func_save()"
                :disabled="func_edit_stop()"
              />
            </div>
            <div class="form__group">
              <span class="label">{{ FIELDS_STATE.password }}:</span>
              <input
                type="text"
                v-model="data.password"
                @change="func_save()"
                :disabled="func_edit_stop()"
              />
            </div>
           
          </div>
        </div>
        <div class="right__detail__form">
          <div class="info">
            <div class="left">
              <div class="form__group">
                <span class="label">Пред.стоимость:</span>
                <input
                  type="number"
                  v-model="data.price"
                  @change="func_save()"
                  :disabled="func_edit_stop()"
                />
              </div>
              <div class="form__group">
                <span class="label">Предоплата:</span>
                <input
                  type="number"
                  v-model="data.prepayment"
                  @change="func_save()"
                  :disabled="func_edit_stop()"
                />
              </div>
            </div>
            <div class="right">
              <div class="form__group">
                <span class="label">Мастер:</span>
                <select v-model="data.master_id" @change="func_update_master">
                  <option
                    :value="item.id"
                    v-for="item in masters_arr"
                    :key="item.id"
                  >
                    {{ item.value }}
                  </option>
                </select>
                <div class="fa__user">
                  <i aria-hidden="true" class="fa fa-pencil-square-o"></i>
                </div>
              </div>
              <div class="form__group">
                <span class="label">Готовность:</span>
                <div class="date_result" v-html="func_get_date_end()"></div>
              </div>
            </div>
          </div>
          <div class="comment__flex">
            <div class="form__group">
              <label>{{ FIELDS_STATE.crash }}</label>
              <textarea
                rows="3"
                v-model="data.crash"
                @change="func_save()"
                :disabled="func_edit_stop()"
              ></textarea>
            </div>
            <div class="form__group">
              <label>{{ FIELDS_STATE.comment }}</label>
              <textarea
                rows="3"
                v-model="data.comment"
                @change="func_save()"
                :disabled="func_edit_stop()"
              ></textarea>
            </div>
          </div>
          <div class="order__products">
            <div class="order__table">
              <div class="header__table">
                <span>Товар</span>
                <span>Стоимость</span>
              </div>
              <div class="border__table">
                <div class="content">
                  <div class="table__items" v-if="product_list.length > 0">
                    <div
                      class="table__item"
                      v-for="(item, index) in product_list"
                      :key="index"
                    >
                      <span>{{ item.product }}</span>
                      <span>{{ Number(item.price).toFixed(2) }} руб</span>
                    </div>
                  </div>
                  <div
                    class="add_btn"
                    @click="func_add_product()"
                    :disabled="func_edit_stop()"
                  >
                    Добавить
                  </div>
                </div>
              </div>
            </div>
            <div class="itog">
              <h4 class="h4__info">Общая стоимость</h4>
              <h4 class="h4__summ">{{ func_get_summ() }} руб</h4>
            </div>
          </div>
        </div>
      </form>
      <div class="blocks__service">
        <div
          class="block_service"
          @click="func_send_sms"
          v-if="this.data && !!Number(this.data.sms_api)"
        >
          <div class="icon">
            <i
              class="fa fa-envelope-o"
              aria-hidden="true"
              name="send_sms"
              value="СМС"
            ></i>
          </div>
          <div class="text">СМС о готовности</div>
          <div class="sms__send" v-if="!!Number(data.sms_send)">
            <i class="fa fa-check" aria-hidden="true"></i>
          </div>
        </div>
        <div class="block_service" @click="func_get_print(1)">
          <div class="icon">
            <i class="fa fa-file-text-o" aria-hidden="true"></i>
          </div>
          <div class="text">Приемная квитанция</div>
        </div>
        <div class="block_service" @click="func_get_print(2)">
          <div class="icon"><i class="fa fa-print" aria-hidden="true"></i></div>
          <div class="text">Накладная</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/select";
import DetailModal from "@/components/Main/details_modal.vue";
import DetailHistory from "@/components/Main/detail_history.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    order_id: String,
    update_detail: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    Select,
    DetailModal,
    DetailHistory,
  },
  data() {
    return {
      modal_detail_option: null,
      history_show: false,
      data: {},
      status_arr: [],
      device_arr: [],
      group_arr: [],
      masters_arr: [],
      product_list: [],
      print: null,
      modal_detail_show: false,
    };
  },
  watch: {
    update_detail(val) {
      if (val) this.func_get_product_list();
    },
  },
  methods: {
    func_get_date_end() {
      let date_end_str =
        this.data && this.data.date_end
          ? new Date(this.data.date_end).toLocaleDateString()
          : "";
      let date_end =
        this.data && this.data.date_end ? new Date(this.data.date_end) : null;
      let date_now = new Date();
      let diffInDays = 0;
      if (date_end) {
        let oneDay = 1000 * 60 * 60 * 24;
        let diffInTime = date_end.getTime() - date_now.getTime();
        diffInDays = Math.round(diffInTime / oneDay);
      }
      let color = "";

      if (diffInDays > 1) {
        color = "#328d30";
      } else if (diffInDays === 1) {
        color = "#dd8b37";
      } else {
        color = "#c74545";
      }
      let status =
        this.status_arr.length > 0
          ? this.status_arr.find(
              (x) => Number(x.id) === Number(this.data.status_id)
            ).value
          : "";
      return status === "Завершен" || status === "Завершен неудачно"
        ? `к ${date_end_str}`
        : `к ${date_end_str} (<span style="color:${color}">${
            diffInDays + " " + this.func_get_word(diffInDays)
          }</span>)`;
    },
    func_get_word(n) {
      n = Math.abs(n) % 100;
      var n1 = n % 10;
      if (n > 10 && n < 20) {
        return "дней";
      }
      if (n1 > 1 && n1 < 5) {
        return "дня";
      }
      if (n1 == 1) {
        return "день";
      }
      return "дней";
    },
    func_show_setting() {
      this.$router.push({ name: "setting", params: { id: 4 } });
    },
    iosDate(date) {
      return date ? date.replace(" ", "T") : "";
    },
    func_edit_stop() {
      let status = this.status_arr.find(
        (x) => Number(x.id) === Number(this.data.status_id)
      );
      if (status) {
        if (
          status.value === "Завершен" ||
          status.value === "Завершен неудачно"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    func_update_master() {
      this.func_save();
    },
    func_update_status(obj) {
      if (obj.value === "Завершен" || obj.value === "Завершен неудачно") {
        this.modal_detail_show = true;
        this.modal_detail_option = obj;
      } else {
        this.data.status_id = obj.id;
        this.func_save();
      }
    },
    func_add_product() {
      if (!this.func_edit_stop()) {
        this.$emit("show_order", {
          order_id: this.order_id,
          order_num: this.data.order_num,
        });
      }
    },
    func_get_data() {
      if (this.order_id && Number(this.order_id)) {
        let url = "order.php/get_detail";
        let param = {
          order_id: this.order_id,
        };
        this.$sendRequest(url, param).then((result) => {
          if (result) {
            if (result.details) this.data = result.details;
            if (result.status) this.status_arr = result.status;
            if (result.device) this.device_arr = result.device;
            if (result.groups) this.group_arr = result.groups;
            if (result.masters) this.masters_arr = result.masters;
          }
        });
      }
    },
    func_update_fire() {
      if (Number(this.data.fire) === 1) {
        this.data.fire = 0;
      } else {
        this.data.fire = 1;
      }
      this.func_save();
    },
    func_save(status = false) {
      if (status === false && this.func_edit_stop()) return;
      let url = "order.php/save_detail";
      let param = this.data;
      if (param) {
        this.$sendRequest(url, param).then((result) => {
          let user_state = { ...this.USER_STATE };
          user_state.amount_card = result.amount_card;
          user_state.amount_nal = result.amount_nal;
          this.$store.commit("CHANGE_USER", user_state);
          this.func_get_obj_data();
        });
      }
    },
    func_get_summ() {
      let res = 0;

      if (this.product_list.length) {
        this.product_list.forEach(function (item) {
          res += Number(item.price);
        });
      }
      return res;
    },
    func_get_obj_data() {
      let status = this.status_arr.find(
        (x) => Number(x.id) === Number(this.data.status_id)
      );
      let device = this.device_arr.find(
        (x) => Number(x.id) === Number(this.data.device_id)
      );
      this.$emit("change_data", {
        status,
        device,
        order_id: this.data.order_id,
        fire: this.data.fire,
        group_name: this.group_arr.find((x) => x.id === this.data.group_id)
          ? this.group_arr.find((x) => x.id === this.data.group_id).value
          : "",
      });
    },
    func_close_detail() {
      this.$emit("close_detail");
    },
    func_send_sms() {
      this.$iosConfirm("Отправить СМС?").then(() => {
        let url = "order.php/send_sms";
        let param = {
          order_id: this.order_id,
        };
        this.$sendRequest(url, param).then((result) => {
          if (result.success) this.data.sms_send = 1;
        });
      });
    },
    func_get_print(type) {
      this.print = null;
      let url = "order.php/get_print";
      this.$sendRequest(url, {
        type,
        order_id: this.order_id,
      }).then((result) => {
        if (!result) return;
        this.print = result;
        setTimeout(() => {
          this.$htmlToPaper("print2");
        }, 1000);
      });
    },
    func_get_product_list() {
      let url = "order.php/get_products_detail";
      this.$sendRequest(url, {
        order_id: this.order_id,
      }).then((result) => {
        if (!result) return;
        this.product_list = result;
      });
    },
    func_detail_cancel(option) {
      let status_old = option.item.status_id.toString();
      let status_new = option.id.toString();

      this.data.status_id = status_new;
      setTimeout(() => {
        this.data.status_id = status_old;
      }, 200);

      this.modal_detail_show = false;
    },
    func_detail_save(option) {
      this.data.status_id = option.id.toString();
      this.modal_detail_show = false;
      this.data.amount_id = option.type_amount;

      if (option.comment) this.data.comment1 = option.comment;
      if (option.itog) this.data.itog = option.itog;

      this.func_save(true);
    },
  },
  mounted() {
    this.func_get_data();
    this.func_get_product_list();
  },
  computed: {
    ...mapGetters(["FIELDS_STATE"]),
    ...mapGetters(["USER_STATE"]),
    customer_name: {
      get() {
        return "ID " + this.data.customer_num + " | " + this.data.customer;
      },
    },
  },
};
</script>

<style></style>
