<template>
  <div class="header__comment__wrapper">

    <div
      class="header__comment header__comment_orange"
      v-if="USER_STATE && USER_STATE.count_day >= 5 && USER_STATE.count_day <= 10"
    >
      {{ "Низкий баланс"  }}
      
      <a style=" color: #32b843; " @click="$emit('show_setting')" >&nbsp;&nbsp;&nbsp;пополнить</a> 
      
      <br>
     
    </div>

    <div
      class="header__comment header__comment_red"
      v-if="USER_STATE && USER_STATE.count_day >= 0 && USER_STATE.count_day <= 4"
    >
      {{ "Низкий баланс"  }} 
      
      <a style=" color: #32b843; " @click="$emit('show_setting')" >&nbsp;&nbsp;&nbsp;пополнить</a> 
      
      <br>
     
    </div>


    <div
      class="header__comment header__comment_red"
      v-if="USER_STATE && USER_STATE.count_day < 0"
    >
      {{ "Отрицательный баланс" }}
      <a style=" color: #32b843; " @click="$emit('show_setting')">&nbsp;&nbsp;&nbsp;пополнить</a> 
      
      <br>
      

    </div>

     <div class="header__comment header__comment_red" v-if="USER_STATE.chat == 0">{{ "Интернет отключен, списание приостановлено." }}<br></div>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
  methods: {
    func_show_plus_balance() {
      this.$router.push("/setting");
    },
  },
};


</script>
<script>
import { mapGetters } from "vuex";
import Setting from "@/components/Main/setting";


export default {
  components: {
    Setting,
  },
  data() {
    return {
      show_setting: false,
    };
  },
 
  methods: {
   
    func_show_page(value) {
      if (value === "setting") {
        this.show_setting = false;
      
      }
    },
  },
  
  computed: {
    ...mapGetters(["MESSAGE_NO_SHOW_STATE"]),
    ...mapGetters(["MESSAGE_LIST_STATE"]),
    ...mapGetters(["USER_STATE"]),
    user_row: {
      get() {
        return this.USER_STATE.row;
      },
      set(value) {
        let user_setting = this.USER_STATE;
        user_setting.row = value;
        this.$store.commit("CHANGE_USER", user_setting);
      },
    },
    
  },
};
</script>



<style>


.fade_down_detail-enter-active,
.fade_down_detail-leave-active {
  transition: 0.5s;
}
.fade_down_detail-enter {
  transform: translateY(100%) scale(0);
}
.fade_down_detail-enter-to {
  transform: translateY(0px) scale(1);
}
.fade_down_detail-leave-to {
  transform: translateY(100%) scale(0);
}

.fade_down-enter-active,
.fade_down-leave-active {
  transition: 0.5s;
  transform: translateY(0px);
}
.fade_down-enter, .fade_down-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  transform: translateY(100%);
}

.fade_up-enter {
  transform: translate(-100%, 100%) scale(0);
}

.fade_up-enter-active {
  transition: transform 0.5s;
}

.fade_up-enter-to {
  transform: translate(0%, 0%) scale(1);
}

.fade_up-leave {
  transition: transform 0.5s;
  transform: translateY(0px);
}
.fade_up-leave-active {
  transition: transform 0.5s;
  transform: translateY(0px);
}

.fade_up-leave-to {
  transform: translateY(100%);
}

.setting_fade-enter {
  transform: translateX(100%);
}

.setting_fade-enter-active {
  transition: 0.5s;
}

.setting_fade-enter-to {
  transform: translateX(0px);
}

.setting_fade-leave {
  transform: translateX(0px);
}
.setting_fade-leave-active {
  transition: transform 0.3s;
}

.setting_fade-leave-to {
  transform: translateX(100%);
}


</style>

