<template>
  <div class="table__products2 table__products22">
    <transition name="add_client">
      <AddProductsZakup
        v-if="show_add_product"
        :group="selected_group"
        @close_product="show_add_product = false"
        @add_product="func_add_product"
      />
      <AddProductsNal
        v-if="show_add_product_nal"
        :group="selected_group"
        :product_nal="product_nal"
        @close_product="show_add_product_nal = false"
        @add_product="func_add_product_nal"
      />
    </transition>
    <div class="table__body">
      <button
        class="add__product"
        @click="show_add_product = true"
        v-if="selected_group"
      >
        Добавить товар в группу "{{ selected_group.value }}"
      </button>
      <div class="header__products" v-if="selected_group">
        <!-- <transition name="search_user"> -->
        <input
          type="text"
          placeholder="Поиск по названию"
          v-model="search.search_name"
        />
        <!-- </transition> -->
        <div class="buttons">
          <button
            class="btn__search tooltip"
            tooltip="Фильтр"
            tooltip-position="bottom right"
            @click="search.show = !search.show"
          >
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>

        <!-- <transition name="search_user"> -->
        <input
          type="text"
          placeholder="Поиск по артикулу"
          v-model="search.search_article"
        />
        <!-- </transition> -->
      </div>
    </div>
    <div class="table__1">
      <table>
        <thead>
          <tr>
            <th>Артикул</th>
            <th>Товар</th>
            <th>Наличие</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="filter_list.length > 0">
          <tr v-for="item in filter_list" :key="item.products_zakup_id">
            <td>
              <input
                type="text"
                v-model="item.article"
                :readonly="item.readonly"
                :class="{ edit: !item.readonly }"
              />
            </td>
            <td>
              <input
                type="text"
                v-model="item.product"
                :readonly="item.readonly"
                :class="{ edit: !item.readonly }"
              />
            </td>
            <td>
              <input
                type="number"
                v-model="item.price"
                :readonly="item.readonly"
                :class="{ edit: !item.readonly }"
              />
            </td>
            <!-- <td>
            <button @click="edit_product(item)">
              <i
                v-if="item.readonly === true"
                class="fa fa-pencil-square-o edit__icon"
                aria-hidden="true"
              ></i>
              <i v-else class="fa fa-check save__icon" aria-hidden="true"></i>
            </button>
          </td> -->
            <td>
              <button @click="remove_product(item)">
                <i class="fa fa-times close__icon" aria-hidden="true"></i>
              </button>
            </td>
            <td>
              <button @click="add_product(item)">
                <i class="fa fa-plus plus__icon" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table__2">
      <h2>Товары в заказе</h2>
      <table>
        <thead>
          <tr>
            <th>Артикул</th>
            <th>Товар</th>
            <th>Куплено</th>
            <th>Стоимость</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="filter_list.length > 0">
          <tr v-for="item in filter_list" :key="item.products_zakup_id">
            <td>
              <input
                type="text"
                v-model="item.article"
                :readonly="item.readonly"
                :class="{ edit: !item.readonly }"
              />
            </td>
            <td>
              <input
                type="text"
                v-model="item.product"
                :readonly="item.readonly"
                :class="{ edit: !item.readonly }"
              />
            </td>
            <td>
              <input
                type="number"
                v-model="item.price"
                :readonly="item.readonly"
                :class="{ edit: !item.readonly }"
              />
            </td>
            <td></td>
            <!-- <td>
            <button @click="edit_product(item)">
              <i
                v-if="item.readonly === true"
                class="fa fa-pencil-square-o edit__icon"
                aria-hidden="true"
              ></i>
              <i v-else class="fa fa-check save__icon" aria-hidden="true"></i>
            </button>
          </td> -->
            <td>
              <button @click="remove_product(item)">
                <i class="fa fa-times close__icon" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AddProductsZakup from "@/components/Products/add_product_zakup";
import AddProductsNal from "@/components/Products/add_product_zakup_nal";
export default {
  components: {
    AddProductsZakup,
    AddProductsNal,
  },
  props: {
    selected_group: {
      type: Object,
      required: false,
    },
  },
  watch: {
    selected_group() {
      this.func_get_data();
    },
  },
  data() {
    return {
      products_list: [],
      show_add_product: false,
      show_add_product_nal: false,
      product_nal: null,
      search: {
        show: false,
        search_name: "",
        search_article: "",
      },
    };
  },
  methods: {
    add_product(item) {
      this.product_nal = item;
      this.show_add_product_nal = true;
    },
    func_get_data() {
      if (!this.selected_group) return;
      let url = "order.php/get_products_zakup";
      this.$sendRequest(url, {
        group_id: this.selected_group.id,
      }).then((result) => {
        if (!result) return;
        result.forEach(function (item) {
          item.readonly = item.readonly === "true" ? true : false;
        });
        this.products_list = result;
      });
    },
    func_add_product_nal() {},
    func_add_product(obj) {
      if (!obj) return;
      this.products_list.unshift(obj);
      this.show_add_product = false;
    },
    remove_product(obj) {
      if (!obj) return;

      if (!obj.readonly) {
        obj.readonly = true;
        return;
      }
      this.$iosConfirm("Удалить товар?").then(() => {
        let url = "order.php/remove_product_zakup";
        this.$sendRequest(url, { product_id: obj.product_zakup_id }).then(
          () => {
            this.products_list.splice(
              this.products_list.findIndex(
                (x) => x.product_zakup_id === obj.product_zakup_id
              ),
              1
            );
          }
        );
      });
    },
    edit_product(obj) {
      if (obj.readonly) {
        this.products_list.forEach(function (item) {
          item.readonly = true;
        });
        obj.readonly = false;
      } else {
        this.products_list.forEach(function (item) {
          item.readonly = true;
        });
        let url = "order.php/edit_product_zakup";
        this.$sendRequest(url, obj);
      }
    },
  },
  computed: {
    filter_list() {
      let search_name = !!this.search.search_name;
      let search_article = !!this.search.search_article;
      return [...this.products_list].filter((x) => {
        if (search_name && search_article) {
          if (
            x.product
              .toLowerCase()
              .indexOf(this.search.search_name.toLowerCase()) !== -1 ||
            x.article
              .toLowerCase()
              .indexOf(this.search.search_article.toLowerCase()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else if (search_name && !search_article) {
          if (
            x.product
              .toLowerCase()
              .indexOf(this.search.search_name.toLowerCase()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else if (!search_name && search_article) {
          if (
            x.article
              .toLowerCase()
              .indexOf(this.search.search_article.toLowerCase()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    },
  },
};
</script>

<style>
.table__products2 .table__body .search_user-enter {
  transform: translateY(-150%);
}

.search_user-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.search_user-enter-to {
  transform: translateY(0);
}
.search_user-leave {
  transform: translateY(0);
}
.search_user-leave-active {
  transition: 0.3s;
}

.search_user-leave-to {
  transform: translateY(-150%);
}
.table__products2 {
  -ms-flex-preferred-size: 70%;
  flex-basis: 70%;
  padding: 10px;
  height: 300px;
  overflow-y: scroll;
  /* ползунок скроллбара */
}

.table__products2::-webkit-scrollbar {
  width: 5px;
  /* ширина для вертикального скролла */
  height: 8px;
  /* высота для горизонтального скролла */
  background-color: #1b1f22;
}

.table__products2::-webkit-scrollbar-thumb {
  background-color: #333333ba;
  border-radius: 9em;
  -webkit-box-shadow: inset 1px 1px 10px #333333ba;
  box-shadow: inset 1px 1px 10px #333333ba;
}

.table__products2::-webkit-scrollbar-thumb:hover {
  background-color: #222;
}

.table__products2 table {
  border-collapse: collapse;
  width: 100%;
}

.table__products2 table thead {
  border-bottom: solid 2px #838383;
  font-size: 12px;
  color: #404040;
  text-align: left;
}

.table__products2 table thead th {
  color: #747474;
  font-weight: normal;
}

.table__products2 table thead th:nth-child(1) {
  width: 15%;
}

.table__products2 table thead th:nth-child(2) {
  width: 64%;
}

.table__products2 table thead th:nth-child(3) {
  width: 15%;
}

.table__products2 table thead th:nth-child(4) {
  width: 3%;
}

.table__products2 table thead th:nth-child(5) {
  width: 3%;
}

.table__products2 table tbody tr {
  border: solid 1px #323232;
  border-left: 0;
  border-right: 0;
  background-color: rgba(255, 255, 255, 0.02);
  text-align: left;
}

.table__products2 table tbody tr.new_client {
  background-color: rgba(126, 189, 64, 0.21);
}

.table__products2 table tbody td {
  color: #747474;
  text-align: left;
  padding: 2px 2px 3px 1px;
  font-size: 0.9em;
  font-weight: 200;
}

.table__products2 table tbody td button {
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 20px;
}

.table__products2 table tbody td:nth-child(1) {
  width: 10%;
}

.table__products2 table tbody td:nth-child(2) {
  width: 20%;
}

.table__products2 table tbody td:nth-child(3) {
  width: 10%;
}

.table__products2 table tbody td:nth-child(4) {
  width: 3%;
}

.table__products2 table tbody td:nth-child(5) {
  width: 57%;
}

.table__products2 table tbody td:nth-child(5) button {
  font-size: 16px;
}

.table__products2 table tbody td input {
  width: 100%;
  background: transparent;
  border: 0;
  outline: none;
  color: #7c7c7c;
}

.table__products2 table tbody td input.edit {
  border-bottom: 1px solid #35698f;
}

.table__products2 table tbody td button.add_order {
  padding: 2px 7px;
  border-radius: 10px;
  color: #646464;
  background: #262626;
  font-size: 12px;
  border: 1px solid #225235;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.table__products2 table tbody td button.add_order:hover {
  background: #505050;
  color: #6d9775;
  border: solid 1px #368f59;
}

.table__products2 table tbody td .profile {
  padding: 3px 5px;
  border-radius: 10px;
  color: #646464;
  background: #262626;
  font-size: 12px;
  border: 1px solid transparent;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.table__products2 table tbody td .profile:hover {
  color: #8f8f8f;
  -webkit-box-shadow: 0px 0px 1px 1px rgba(169, 169, 169, 0.26);
  box-shadow: 0px 0px 1px 1px rgba(169, 169, 169, 0.26);
}

.table__products2 table tbody td .review_stars_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.table__products2 table tbody td .review_stars_wrap i {
  font-size: 18px;
}

.table__products2 table tbody td .review_stars_wrap i.checked.checked,
.table__products2 table tbody td .review_stars_wrap i.checked.checked ~ i {
  color: #ec9801;
}

.table__products2 .table__body button.add__product {
  padding: 2px 7px;
  border-radius: 10px;
  color: #646464;
  background: #262626;
  font-size: 12px;
  border: 1px solid #225235;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  margin-right: 10px;
}

.table__products2 .table__body {
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: space-between;
}
</style>
