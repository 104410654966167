<template>
  <div class="zakup__wrapper2">
    <div class="header__title">
      <h2>Новая закупка</h2>
    </div>
    <div class="zakup__content">
      <h3>Товары</h3>
      <div class="content__flex">
        <div class="content__left">
          <div class="group__list">
            <form @submit.prevent="func_save_group">
              <div class="input__add">
                <input
                  type="text"
                  v-model="group"
                  required
                  placeholder="Добавить группу услуг"
                />
                <button><i class="fa fa-plus" aria-hidden="true"></i></button>
              </div>
            </form>
            <div class="group__body" v-if="group_list.length > 0">
              <div
                class="group__item"
                v-for="item in group_list"
                :key="item.id"
                :class="{
                  selected: selected_group && selected_group.id === item.id,
                }"
                @click="func_select_group_item(item)"
              >
                <div class="title">
                  <input
                    type="text"
                    name=""
                    id=""
                    v-model="item.value"
                    :readonly="item.readonly === true"
                    :class="{ edit: !item.readonly }"
                  />
                </div>
                <div class="buttons">
                  <div class="edit" @click="func_edit_group(item)">
                    <i
                      v-if="item.readonly === true"
                      class="fa fa-pencil-square-o edit__icon"
                      aria-hidden="true"
                    ></i>
                    <i
                      v-else
                      class="fa fa-check save__icon"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="remove" @click="func_remove_group(item)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="comment">
            <div class="form__group">
              <label for="">Комменарий</label>
              <textarea v-model="comment" rows="5"></textarea>
            </div>
          </div>
        </div>
        <div class="content__right">
          <TableProduct
            :selected_group="selected_group"
            @show_add_product="show_add_product = true"
          />
        </div>
      </div>
    </div>
    <div class="zakup__footer">
      <div class="zakup__footer__button">
        <button>Назад</button>
      </div>
      <div class="zakup__footer__fields">
        <div class="form__group">
          <label>Поставщик:</label>
          <div class="input__wrap">
            <input type="text" readonly />
          </div>
        </div>
        <div class="form__group">
          <label>Номер квитанции:</label>
          <div class="input__wrap">
            <input type="text" readonly />
          </div>
        </div>
      </div>
      <div class="zakup__footer__fields">
        <div class="form__group">
          <label>Товаров:</label>
          <div class="input__wrap">
            <input type="text" readonly />
          </div>
        </div>
        <div class="form__group">
          <label>На сумму:</label>
          <div class="input__wrap">
            <input type="text" readonly />
          </div>
        </div>
      </div>
      <div class="zakup__footer__button">
        <button>Добавить</button>
      </div>
    </div>
  </div>
</template>

<script>
import TableProduct from "@/components/Products/zakup_product_table";
export default {
  components: {
    TableProduct,
  },
  data() {
    return {
      group_list: [],
      selected_group: null,
      group: null,
      comment: "",
    };
  },
  methods: {
    func_save_group() {
      let url = "order.php/add_group_zakup";
      this.$sendRequest(url, { value: this.group }).then((result) => {
        if (!result) return;
        result.readonly = result.readonly === "true" ? true : false;
        this.group_list.unshift(result);
        this.group = "";
      });
    },
    func_edit_group(obj) {
      if (obj.readonly) {
        this.group_list.forEach(function (item) {
          item.readonly = true;
        });
        obj.readonly = false;
      } else {
        this.group_list.forEach(function (item) {
          item.readonly = true;
        });
        let url = "order.php/edit_group_zakup";
        this.$sendRequest(url, obj);
      }
    },
    func_remove_group(item) {
      if (!item) return;

      if (!item.readonly) {
        item.readonly = true;
        return;
      }
      this.$iosConfirm("Удалить группу?").then(() => {
        let url = "order.php/remove_group_zakup";
        this.$sendRequest(url, { id: item.id }).then(() => {
          this.group_list.splice(
            this.group_list.findIndex((x) => x.id === item.id),
            1
          );
          this.selected_group = null;
        });
      });
    },
    func_select_group_item(obj) {
      this.selected_group = obj;
    },
    func_get_group() {
      let url = "order.php/get_groups_zakup";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        result.forEach(function (item) {
          item.readonly = item.readonly === "true" ? true : false;
        });
        this.group_list = result;
      });
    },
  },
  mounted() {
    this.func_get_group();
  },
};
</script>

<style>
.zakup__wrapper {
  height: 100%;
}
.zakup__wrapper .header__title h2 {
  text-align: center;
  font-size: 1rem;
  border-bottom: solid 1px #ffffff;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  letter-spacing: 0.2rem;
  font-weight: 300;
  line-height: 1.5;
  padding-bottom: 0.5rem;
  margin: 0 auto;
}
.zakup__wrapper .zakup__content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.zakup__wrapper .zakup__content h3 {
  text-align: center;
  margin-top: 40px;
  font-size: 25px;
  color: #525252;
  font-weight: normal;
  margin-bottom: 5px;
  text-align: center;
}

.zakup__wrapper .zakup__content .content__flex {
  display: flex;
  height: 100%;
}
.zakup__wrapper .zakup__content .content__flex .content__left {
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
}
.zakup__wrapper .zakup__content .content__flex .content__left .group__list {
  flex: 1 1 auto;
}
.zakup__wrapper .zakup__content .content__flex .content__right {
  flex: 1 1 70%;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  form {
  padding: 0px;
  padding-right: 15px;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .input__add {
  margin-top: 10px;
  padding: 0 10px;
  border: 0px solid #787878;
  background: #3333334a;
  border-radius: 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #7c7c7c;
  text-align: left;
  font-size: 16px;
  margin: 10px 5px;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .input__add
  input {
  background: transparent;
  width: 100%;
  font-size: 13px;
  border: 0;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  color: #438541;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .input__add
  button {
  color: #438541;
  background: transparent;
  border: 0;
  font-size: 18px;
  cursor: pointer;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body {
  margin-top: 30px;
  width: 100%;
  overflow-y: scroll;
  padding-right: 10px;
  /* ползунок скроллбара */
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body::-webkit-scrollbar {
  width: 5px;
  /* ширина для вертикального скролла */
  height: 8px;
  /* высота для горизонтального скролла */
  background-color: #1b1f22;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body::-webkit-scrollbar-thumb {
  background-color: #333333ba;
  border-radius: 9em;
  -webkit-box-shadow: inset 1px 1px 10px #333333ba;
  box-shadow: inset 1px 1px 10px #333333ba;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body::-webkit-scrollbar-thumb:hover {
  background-color: #222;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body
  .group__item {
  padding: 10px;
  border: 0px solid #787878;
  background: #3333334a;
  border-radius: 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #7c7c7c;
  text-align: left;
  font-size: 16px;
  margin: 10px 5px;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body
  .group__item
  input {
  background: transparent;
  border: 0;
  outline: none;
  color: #7c7c7c;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body
  .group__item
  input.edit {
  border-bottom: 1px solid #35698f;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body
  .group__item
  .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body
  .group__item.selected {
  background: #5e5e5e4a;
  -webkit-box-shadow: 0 0 10px rgba(91, 144, 181, 0.5);
  box-shadow: 0 0 10px rgba(91, 144, 181, 0.5);
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body
  .group__item:hover {
  background: #5e5e5e4a;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .group__list
  .group__body
  .group__item
  .remove {
  margin-left: 10px;
  color: #d94848c2;
  font-size: 15px;
  background: #2c2222;
  cursor: pointer;
}

.products__table__wrapper .header__products {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.products__table__wrapper .header__products button {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 24px;
  color: #c1c1c1;
  cursor: pointer;
}

.products__table__wrapper .header__products button.btn__add {
  margin-right: 18px;
  margin-left: 10px;
}

.products__table__wrapper .header__products button.btn__add:hover {
  color: #90cf97;
}

.products__table__wrapper .header__products button:hover {
  color: #5e97c7;
}

.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .comment
  .form__group
  textarea {
  background: #333;
  min-width: 100%;
  margin: 0px 0px 10px 0px;
  padding: 0.75rem 1rem;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  border-radius: 4px;
  border: solid 0px #ffffff;
  color: inherit;
  width: 100%;
  margin-top: 5px;
}
.zakup__wrapper
  .zakup__content
  .content__flex
  .content__left
  .comment
  .form__group
  label {
  color: #7e7e7e !important;
}
</style>