<template>
  <div class="zakup__distr__wrapper">
    <div class="zakup__distr__header">
      <h2>Поставщики</h2>
    </div>
    <div class="zakup__distr__body">
      <form @submit.prevent="func_save_distr">
        <div class="input__add">
          <input
            type="text"
            v-model="distr"
            required
            placeholder="Добавить поставщика"
          />
          <button><i class="fa fa-plus" aria-hidden="true"></i></button>
        </div>
      </form>
      <div class="zakup__distr__list">
        <div
          class="zakup__distr__list___item"
          v-for="item in distr_list"
          :key="item.distr_id"
        >
          <div class="title">{{ item.distr }}</div>
          <div class="remove" @click="func_remove_distr(item.distr_id)">
            <i aria-hidden="true" class="fa fa-times"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="zakup__footer">
      <button @click="$emit('prev')">Назад</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      distr: "",
      distr_list: [],
    };
  },
  methods: {
    func_save_distr() {
      if (!this.distr) return;
      let url = "order.php/add_distr";
      this.$sendRequest(url, {
        distr: this.distr,
      }).then((result) => {
        this.distr_list.push({
          id: result.id,
          distr: this.distr,
        });
        this.distr = "";
      });
    },
    func_remove_distr(id) {
      if (!id) return;
      let url = "order.php/remove_distr";
      this.$sendRequest(url, {
        distr_id: id,
      }).then(() => {
        this.distr_list.splice(
          this.distr_list.findIndex((x) => x.id === id),
          1
        );
      });
    },
    func_get_distr() {
      let url = "order.php/get_distr";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.distr_list = result;
      });
    },
  },
  mounted() {
    this.func_get_distr();
  },
};
</script>

<style>
</style>