<template>
  <div
    class="modal__pay__popup"
    :class="[$store.state.user_setting.blur ? 'blur' : 'no-blur']"
  >
    <div class="modal__pay__wrapper">
      <div class="header__back_btn2">
      

        <div @click="$emit('close_modal')" class="close__setting"><i aria-hidden="true" class="fa fa-times"></i></div>
      </div>
      
    
<div class="header__comment" v-html="title"></div>



    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    tarif: String,
  },
    computed: {
    ...mapGetters(["USER_STATE"]),
  },
  data() {
    return {
      prozivol_summ: 100,
      title:'<',
    };
  },
  methods: {
    func_pay(summ) {
      if (isNaN(Number(summ))) return;
      if (Number(summ) < 100) return;

      let url = "order.php/payment";
      this.$sendRequest(url, {
        amount: summ,
      }).then((result) => {
        if (!result) return;
        if (!result.link) return;
        window.open(result.link, "_blank");
      });
    },
  },
  mounted(){
    this.title = this.USER_STATE.title;
    console.log(this.title);
  }
};
</script>


<style></style>
