<template>
  <div class="products__table__wrapper">
    <div
      class="close__products tooltip"
      @click="$router.push('/')"
      tooltip="Назад"
      tooltip-position="bottom left"
    >
      <i class="fa fa-external-link" aria-hidden="true"></i>
    </div>
    <div class="product__wrapper">
      <div class="products__link">
        <div
          class="item__link"
          v-for="item in links.filter((x) => x.hidden === false)"
          :key="item.id"
          :class="{ active: item.active }"
          @click="func_select_link(item)"
        >
          <div class="content">
            <div class="img">
              <i class="fa" :class="[item.css]" aria-hidden="true"></i>
            </div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <div class="product__body">
        <Products v-if="selected_id === 1" />
        <Service v-if="selected_id === 2" />
        <Zakup v-if="selected_id === 3" />
      </div>
    </div>
  </div>
</template>
<script>
import Products from "@/components/Products/products_main.vue";
import Service from "@/components/Products/service_main.vue";
import Zakup from "@/components/Products/zakup_main.vue";
export default {
  components: {
    Products,
    Service,
    Zakup,
  },
  data() {
    return {
      selected_id: 1,
      links: [
        {
          id: 1,
          text: "Товары",
          css: "fa-cube",
          active: true,
          hidden: false,
        },
        {
          id: 2,
          text: "Услуги",
          css: "fa-wrench",
          active: false,
          hidden: false,
        },
        {
          id: 3,
          text: "Закупки",
          css: "fa-sign-in",
          active: false,
          hidden: false,
        },
        {
          id: 4,
          text: "Возвраты",
          css: "fa-sign-out",
          active: false,
          hidden: false,
        },
      ],
    };
  },
  methods: {
    func_select_link(item) {
      this.links.forEach((obj) => {
        obj.active = false;
      });
      item.active = true;
      this.selected_id = item.id;
    },
  },
  mounted() {},
};
</script>

<style>
</style>
