<template>
  <div class="subscript__wrapper">
    <Loader :show="progress" />
    <ModalPay
      v-if="show_modal_pay"
      @close_modal="show_modal_pay = false"
      :tarif="tarif"
    />
    <div class="header__subscribe">
      <div class="header">
        <div class="balance__header">
          
          <span style=" font-size: 20px; ">Баланс:&nbsp;&nbsp;&nbsp;<a class="price" :style="{ color: func_get_color() }">
            {{ balance }} <i class="fa fa-rub" aria-hidden="true"></i></a> </span>
          
        </div>
        <div class="balance__info">
          <div class="info__item">
            <span>Расход в день</span>
            <span class="price"
              >{{ USER_STATE.price_day }}<i class="fa fa-rub" aria-hidden="true"></i
            ></span>
          </div>
          <div class="info__item">
            <span>Дней оплачено</span>
            <span class="price" :style="{ color: func_get_color() }">{{
              USER_STATE.count_day
            }}</span>
          </div>
          
        </div>

<button @click="show_modal_pay = true">Тариф</button>
     
        
      </div>







 <transition name="setting_fade">
        <Setting
          v-if="show_setting"
          @close_setting="show_setting = false"
          @change_row="func_change_class"
          @show_obj="func_show_page"
        />
      </transition>

    </div>
    <div class="body__subscribe">
      <FinanceList @load_end="progress = false" />
    </div>
  </div>
</template>


 



<script>
import FinanceList from "@/components/SettingUser/finance_list";
import Loader from "@/components/loader";
import ModalPay from "@/components/SettingUser/modal_pay";
import { mapGetters } from "vuex";

export default {
  components: {
    FinanceList,
    Loader,
    ModalPay,
  },
  data() {
    return {
      show_setting: false,
      
      balance: 0,
      tarif: 0,
      days: 0,
      progress: true,
      summ_pay: 0,
      show_modal_pay: false,
    };
  },
  methods: {
    func_pay() {
      if (Number(this.summ_pay) <= 0) return;
      let url = "order.php/payment";
      this.$sendRequest(url, {
          amount: this.summ_pay,
        })
        .then((result) => {
          if (!result) return;
          if (!result.link) return;
          window.open(result.link, "_blank");
        });
    },
    func_change_class(value) {
      let user_setting = this.USER_STATE;
      user_setting.row = value;
      this.$store.commit("CHANGE_USER", user_setting);
    },
    func_show_page(value) {
      if (value === "setting") {
        this.show_setting = false;
      }
    },
    func_get_data() {
      this.progress = true;
      let url = "order.php/get_podpis_data";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.title = result.title;
        this.balance = result.balance;
        this.tarif = result.tarif;
        this.func_get_count_day();
      });
    },
    func_get_count_day() {
      this.days =
        Math.floor(this.balance / this.tarif) < 0
          ? 0
          : Math.floor(this.balance / this.tarif);
    },
    func_get_color() {
      if (this.days > 7) {
        return "#64b4d3";
      } else if (this.days <= 7 && this.days > 3) {
        return "#ff962a";
      } else {
        return "#d54949";
      }
    },
  },
  mounted() {
    this.func_get_data();
    
  },
  
  computed: {
    ...mapGetters(["USER_STATE"]),
    str_tarif: {
      get() {
        return this.tarif;
      },
      set(value) {
        this.tarif = value;
      },
    },
    str_day: {
      get() {
        return this.days;
      },
      set(value) {
        this.days = value;
      },
    },
  },
};
</script>


<style>


.fade_down_detail-enter-active,
.fade_down_detail-leave-active {
  transition: 0.5s;
}
.fade_down_detail-enter {
  transform: translateY(100%) scale(0);
}
.fade_down_detail-enter-to {
  transform: translateY(0px) scale(1);
}
.fade_down_detail-leave-to {
  transform: translateY(100%) scale(0);
}

.fade_down-enter-active,
.fade_down-leave-active {
  transition: 0.5s;
  transform: translateY(0px);
}
.fade_down-enter, .fade_down-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  transform: translateY(100%);
}

.fade_up-enter {
  transform: translate(-100%, 100%) scale(0);
}

.fade_up-enter-active {
  transition: transform 0.5s;
}

.fade_up-enter-to {
  transform: translate(0%, 0%) scale(1);
}

.fade_up-leave {
  transition: transform 0.5s;
  transform: translateY(0px);
}
.fade_up-leave-active {
  transition: transform 0.5s;
  transform: translateY(0px);
}

.fade_up-leave-to {
  transform: translateY(100%);
}

.setting_fade-enter {
  transform: translateX(100%);
}

.setting_fade-enter-active {
  transition: 0.5s;
}

.setting_fade-enter-to {
  transform: translateX(0px);
}

.setting_fade-leave {
  transform: translateX(0px);
}
.setting_fade-leave-active {
  transition: transform 0.3s;
}

.setting_fade-leave-to {
  transform: translateX(100%);
}

</style>
