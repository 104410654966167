<template>
  <div class="zakup__main">
    <div class="zakup__buttons" v-if="menu_selected == null">
      <button @click="menu_selected = 1">Новая закупка</button>
      <button @click="menu_selected = 2">История закупок</button>
      <button @click="menu_selected = 3">Поставщики</button>
    </div>
    <div class="zakup__wrapper">
      <ZakupNew v-if="menu_selected === 1" @prev="menu_selected = null" />
      <ZakupHistory v-if="menu_selected === 2" @prev="menu_selected = null" />
      <ZakupDistr v-if="menu_selected === 3" @prev="menu_selected = null" />
    </div>
  </div>
</template>

<script>
import ZakupNew from "@/components/Products/zakup_new";
import ZakupHistory from "@/components/Products/zakup_history";
import ZakupDistr from "@/components/Products/zakup_distr";
export default {
  components: {
    ZakupNew,
    ZakupHistory,
    ZakupDistr,
  },
  data() {
    return {
      menu_selected: null,
    };
  },
  functions: {},
};
</script>

<style>
</style>