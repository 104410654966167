<template>
  <div class="finance__list__wrapper">
    <table v-if="finance_list.length > 0">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in finance_list" :key="item.finance_id">
          <td>
            {{
              new Date(iosDate(item.create_date)).toLocaleDateString() +
              " " +
              new Date(iosDate(item.create_date)).toLocaleTimeString()
            }}
          </td>
          <td :style="{ color: func_get_color(item) }">{{ item.type }}</td>
          <td :style="{ color: func_get_color(item) }">
            {{ Number(item.summ || 0) > 0 ? "+" + item.summ : item.summ }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    user_id: {
      type: Number,
      required: false,
    },
    refresh_finance: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      finance_list: [],
    };
  },
  methods: {
    iosDate(date) {
      return date ? date.replace(" ", "T") : "";
    },
    func_get_finance_list() {
      let url = "order.php/get_finance_list";
      this.$sendRequest(url, { user_id: this.user_id ? this.user_id : 0 }).then(
        (result) => {
          if (!result) return;
          this.finance_list = result;
          this.$emit("load_end");
        }
      );
    },
    func_get_color(item) {
      let color = "";
      // if (balance <= 0) {
      //   color = "#c94e4e";
      // } else if (balance > 0 && balance < 50) {
      //   color = "#9f6427";
      // } else {
      //   color = "#217e2d";
      // }

      if (item.type === "Пополнение") {
        color = "#217e2d";
      } else if (item.type) {
        color = "#747474";
      } else {
        color = "#217e2d";
      }

      return color;
    },
  },
  watch: {
    refresh_finance: function (val) {
      if (val) {
        this.func_get_finance_list();
      }
    },
  },
  mounted() {
    this.func_get_finance_list();
  },
};
</script>

<style>
.finance__list__wrapper {
   margin-top: 30px;
  position: absolute;
  width: 100%;
  overflow-y: scroll;
  height: 70%;
  padding-right: 20px;
  /* ползунок скроллбара */
}

.finance__list__wrapper::-webkit-scrollbar {
  width: 5px;
  /* ширина для вертикального скролла */
  height: 8px;
  /* высота для горизонтального скролла */
  background-color: #1b1f22;
}

.finance__list__wrapper::-webkit-scrollbar-thumb {
  background-color: #333333ba;
  border-radius: 9em;
  -webkit-box-shadow: inset 1px 1px 10px #333333ba;
  box-shadow: inset 1px 1px 10px #333333ba;
}

.finance__list__wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #222;
}

.finance__list__wrapper table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.finance__list__wrapper table thead {
  border-bottom: solid 2px #838383;
  font-size: 12px;
  color: #404040;
  font-weight: normal;
}

.finance__list__wrapper table thead tr th {
  color: #747474;
  font-weight: normal;
}

.finance__list__wrapper table thead tr th:nth-child(1) {
  width: 80%;
}

.finance__list__wrapper table thead tr th:nth-child(2) {
  width: 10%;
}

.finance__list__wrapper table thead tr th:nth-child(3) {
  text-align: left;
  width: 10%;
}

.finance__list__wrapper table tbody tr {
  background-color: rgba(255, 255, 255, 0.02);
  border: solid 1px #323232;
  border-left: 0;
  border-right: 0;
}

.finance__list__wrapper table tbody tr td {
  color: #747474;
  font-size: 0.9em;
  font-weight: 200;
  padding: 5px 10px 3px 6px;
  text-align: left;
}
</style>
