<template>
  <div class="table__products">
    <transition name="add_client">
      <AddService
        v-if="show_add_product"
        :group="selected_group"
        @close_product="show_add_product = false"
        @add_product="func_add_product"
      />
    </transition>
    <div class="table__body">
      <button
        class="add__product"
        @click="show_add_product = true"
        v-if="selected_group"
      >
        Добавить услугу в группу "{{ selected_group.value }}"
      </button>
      <div class="header__products" v-if="selected_group">
        <!-- <transition name="search_user"> -->
        <input
          type="text"
          placeholder="Поиск по названию"
          v-model="search.search_name"
        />
        <!-- </transition> -->
        <div class="buttons">
          <button
            class="btn__search tooltip"
            tooltip="Фильтр"
            tooltip-position="bottom right"
            @click="search.show = !search.show"
          >
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>

        <!-- <transition name="search_user"> -->
        <input
          type="text"
          placeholder="Поиск по артикулу"
          v-model="search.search_article"
        />
        <!-- </transition> -->
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>Артикул</th>
          <th>Услуга</th>
          <th>Стоимость</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="filter_list.length > 0">
        <tr v-for="item in filter_list" :key="item.service_id">
          <td>
            <input
              type="text"
              v-model="item.article"
              :readonly="item.readonly"
              :class="{ edit: !item.readonly }"
            />
          </td>
          <td>
            <input
              type="text"
              v-model="item.service"
              :readonly="item.readonly"
              :class="{ edit: !item.readonly }"
            />
          </td>
          <td>
            <input
              type="number"
              v-model="item.price"
              :readonly="item.readonly"
              :class="{ edit: !item.readonly }"
            />
          </td>
          <td>
            <button @click="edit_product(item)">
              <i
                v-if="item.readonly === true"
                class="fa fa-pencil-square-o edit__icon"
                aria-hidden="true"
              ></i>
              <i v-else class="fa fa-check save__icon" aria-hidden="true"></i>
            </button>
          </td>
          <td>
            <button @click="remove_product(item)">
              <i class="fa fa-times close__icon" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AddService from "@/components/Products/add_service_modal";
export default {
  components: {
    AddService,
  },
  props: {
    selected_group: {
      type: Object,
      required: false,
    },
  },
  watch: {
    selected_group() {
      this.func_get_data();
    },
  },
  data() {
    return {
      products_list: [],
      show_add_product: false,
      search: {
        show: false,
        search_name: "",
        search_article: "",
      },
    };
  },
  methods: {
    func_get_data() {
      if (!this.selected_group) return;
      let url = "order.php/get_services";
      this.$sendRequest(url, {
        group_id: this.selected_group.id,
        person: 0,
      }).then((result) => {
        if (!result) return;
        result.forEach(function (item) {
          item.readonly = item.readonly === "true" ? true : false;
        });
        this.products_list = result;
      });
    },
    func_add_product(obj) {
      if (!obj) return;
      this.products_list.unshift(obj);
      this.show_add_product = false;
    },
    remove_product(obj) {
      if (!obj) return;

      if (!obj.readonly) {
        obj.readonly = true;
        return;
      }
      this.$iosConfirm("Удалить товар?").then(() => {
        let url = "order.php/remove_service";
        this.$sendRequest(url, { product_id: obj.service_id }).then(() => {
          this.products_list.splice(
            this.products_list.findIndex(
              (x) => x.service_id === obj.service_id
            ),
            1
          );
        });
      });
    },
    edit_product(obj) {
      if (obj.readonly) {
        this.products_list.forEach(function (item) {
          item.readonly = true;
        });
        obj.readonly = false;
      } else {
        this.products_list.forEach(function (item) {
          item.readonly = true;
        });
        let url = "order.php/edit_service";
        this.$sendRequest(url, obj);
      }
    },
  },
  computed: {
    filter_list() {
      let search_name = !!this.search.search_name;
      let search_article = !!this.search.search_article;
      return [...this.products_list].filter((x) => {
        if (search_name && search_article) {
          if (
            x.product
              .toLowerCase()
              .indexOf(this.search.search_name.toLowerCase()) !== -1 ||
            x.article
              .toLowerCase()
              .indexOf(this.search.search_article.toLowerCase()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else if (search_name && !search_article) {
          if (
            x.product
              .toLowerCase()
              .indexOf(this.search.search_name.toLowerCase()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else if (!search_name && search_article) {
          if (
            x.article
              .toLowerCase()
              .indexOf(this.search.search_article.toLowerCase()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    },
  },
};
</script>

<style>
.search_user-enter {
  transform: translateY(-150%);
}

.search_user-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.search_user-enter-to {
  transform: translateY(0);
}
.search_user-leave {
  transform: translateY(0);
}
.search_user-leave-active {
  transition: 0.3s;
}

.search_user-leave-to {
  transform: translateY(-150%);
}
</style>
